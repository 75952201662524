import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import client from '../assets/images/client.jpg';
import cost from '../assets/images/cost.jpg';
import quality from '../assets/images/quality.jpg';
import durability from '../assets/images/durability.jpg';
import damaged from '../assets/images/damaged.jpg';
import reconditioned from '../assets/images/reconditioned.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <p className="text-white-50">
              Phuma Phambili (Pty) Ltd was registered in 2005, mainly to
              recondition rock drill cylinders for the mining industry. The
              hard-chroming plant was designed specifically to meet this
              requirement. Our grinding facility is specifically designed and
              equipped for specialised precision grinding on a wide range of
              hard materials including Silicone Carbide, Tungsten Carbide and
              alumina oxide ceramics. Tolerances of 0.01mm are well within our
              range of capabilities. .
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={quality} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Quality Assurance</h4>
              <p className="text-black-50 mb-0">
                We guarantee a repair turnaround time of 15 working days while
                maintaining our high quality standards. We consistently aim to
                meet the original standard of the cylinder and rectify faulty
                repairs. Furthermore, repair Generation 2 and 3 cylinders so
                that they retain low noise levels of 102 decibels.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={cost} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">Cost Reduction</h4>
                  <p className="mb-0 text-white-50">
                    Due to extreme underground conditions, rockdrill cylinders
                    are a significant recurring cost for mines. Phuma Phambili’s
                    reconditioned cylinders significantly reduce the cost per
                    rockdrill cylinder, up to a third of the cost of a new
                    cylinder, depending on the extent of the cylinder’s damage
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={durability} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">Durability & Resilience</h4>
                  <p className="mb-0 text-white-50">
                    New cylinders are not chromed, thus reducing their
                    durability underground. Phuma Phambili’s method of chroming
                    ensures that the cylinders are not only reconditioned to
                    their original condition, but they are also
                    repeatedly-reusable. Chroming ensures that our cylinders
                    resist underground friction and rusting.
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={client} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Client Satisfaction</h4>
              <p className="text-black-50 mb-0">
                In the 13 years of Phuma Phambili’s operations, we have
                maintained and grown our client base. Our reputable clients
                include: Anglo American Platinum, Impala Platinum, Lonmin and
                Sibanye Stillwater.
              </p>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-12">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">TYPES OF CYLINDERS AND USE</h4>
                  <p className="mb-0 text-white-50">
                    Phuma Phambili refurbishes cylinders for the following
                    pneumatic rockdrills:
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={damaged} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">S215 Cylinders</h4>
                  <p className="mb-0 text-white-50">
                    Used forS215 drilling machines/jackhammer which are used in
                    stope drilling.
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={reconditioned} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">S25 Cylinders</h4>
                  <p className="mb-0 text-white-50">
                    For repairing S25 drilling machines which are used in new
                    area development.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
